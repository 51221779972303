import "bootstrap-icons/font/bootstrap-icons.css";
import "assets/custom-sgds.scss";

import "reactgowhere/dist/assets/fonts/index.css";
import "reactgowhere/dist/assets/fonts/font-awesome.min.css";

import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";

import { GoWhereApp } from "reactgowhere";
import { theme } from "./app/theme";

const HomePage = lazy(() => import("./pages/Home"));
const FaqPage = lazy(() => import("./pages/FAQ"));
const NotFoundPage = lazy(() => import("./pages/404"));

const CDN_URL = process.env.REACT_APP_CDN_URL;

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <GoWhereApp theme={theme} product={"maskgowhere"} cdnUrl={CDN_URL}>
        <Suspense fallback="">
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/faq" component={FaqPage} />
            <Route component={() => <NotFoundPage />} />
          </Switch>
        </Suspense>
      </GoWhereApp>
    </BrowserRouter>
  </React.StrictMode>,

  document.getElementById("root")
);
