import { Theme } from "reactgowhere";
import Background from "assets/background.jpg";
import GovTech from "assets/logo-govtech.jpg";
import PA from "assets/logo-pa.png";
import NotFoundImage from "assets/images/notFoundImage.svg";

import { env } from "./config";

export const theme: Theme = {
  // title: {
  //   english: "MaskGoWhere",
  //   chinese: "",
  //   malay: "",
  //   tamil: ""
  // },
  // subTitle: {
  //   english: "is brought to you by:",
  //   chinese: "口罩派发信息网是由以下机构共同设立：",
  //   malay: "Pengedaran Pelitup dibawakan kepada anda oleh:",
  //   tamil: "முகக் கவச விவரங்களை உங்களுக்கு வழங்கியது:"
  // },
  siteLastUpdated: env.LAST_UPDATED,
  agencies: [
    { name: "GovTech", image: GovTech, height: 75, width: 75 },
    { name: "People's Association", image: PA, height: 75, width: 75 }
  ],
  heroImage: Background,
  primaryColor: "#e62e7b",
  secondaryColor: "#e62e7b",
  heroDividerColor: "#e62e7b",
  backToTop: true,
  notFoundPage: {
    image: NotFoundImage,
    heroBackground: "#F6F6F6"
  }
};
